export const LANGUAGE_SET = 'LANGUAGE_SET';
export function setLanguage(language) {
  return {
    type: LANGUAGE_SET,
    language,
  };
}

export const ROOT_CLASSES_ADD = 'ROOT_CLASSES_ADD';
export function addRootClasses(classes) {
  return {
    type: ROOT_CLASSES_ADD,
    classes,
  };
}

export const ROOT_CLASSES_REMOVE = 'ROOT_CLASSES_REMOVE';
export function removeRootClasses(classes) {
  return {
    type: ROOT_CLASSES_REMOVE,
    classes,
  };
}

export const ENABLE_MAINTENANCE_MODE = 'ENABLE_MAINTENANCE_MODE';
export function enableMaintenanceMode() {
  return {
    type: ENABLE_MAINTENANCE_MODE,
  };
}

export const DISABLE_MAINTENANCE_MODE = 'DISABLE_MAINTENANCE_MODE';
export function disableMaintenanceMode() {
  return {
    type: DISABLE_MAINTENANCE_MODE,
  };
}

export const SET_CHECKBOX = 'SET_CHECKBOX';
export function setCheckbox(id, checked) {
  return {
    type: SET_CHECKBOX,
    id,
    checked,
  };
}

export const TRACK_EVENT = 'TRACK_EVENT';

export const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU';
export function showMobileMenu() {
  return (dispatch) => {
    dispatch(addRootClasses('show-nav'));
    dispatch({ type: SHOW_MOBILE_MENU });
  };
}

export const HIDE_MOBILE_MENU = 'HIDE_MOBILE_MENU';
export function hideMobileMenu() {
  return (dispatch) => {
    dispatch(removeRootClasses('show-nav'));
    dispatch({ type: HIDE_MOBILE_MENU });
  };
}

/**
 * Track an event through redux-beacon
 * @param {*} category a category.
 * @param {*} action an action performed by the user.
 * @param {*} label A label associated to give context to the category.
 * @param {number} value A value ( if applicable. )
 * @returns {Array} action.
 */
export const trackEvent = (category, action, label, value) => {
  return {
    type: TRACK_EVENT,
    category: category,
    action: action,
    label: label,
    value: value,
  };
};

export const COOKIE_CONSENT_GRANTED = 'COOKIE_CONSENT_GRANTED';

/**
 * Tell redux-beacon and GTM to load additional tags after consent.
 * @returns {Array} action.
 */
export const cookieConsentGranted = () => {
  return {
    type: COOKIE_CONSENT_GRANTED,
  };
};
