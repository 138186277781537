import { List, fromJS } from 'immutable';
import { DEFAULT_LANGUAGE } from '../../../config/constants';

import {
  ROOT_CLASSES_ADD,
  ROOT_CLASSES_REMOVE,
  LANGUAGE_SET,
  DISABLE_MAINTENANCE_MODE,
  ENABLE_MAINTENANCE_MODE,
  SET_CHECKBOX,
  SHOW_MOBILE_MENU,
  HIDE_MOBILE_MENU,
} from '../../actions/UI';

import {
  REFERENCE_LOADING_START,
  REFERENCE_LOADING_DONE,
  REFERENCE_LOADING_RESET,
  REFERENCE_LOADING_ENABLE_PAGE_LOADER,
} from '../../actions/UI/ReferenceLoading';

const initialState = fromJS({
  language: DEFAULT_LANGUAGE,
  rootClasses: [],
  maintenanceMode: false,
  showMobileMenu: false,
  referenceLoading: {
    itemsStarted: null,
    itemsFinished: null,
  },
});

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case ENABLE_MAINTENANCE_MODE: {
      return state.set('maintenanceMode', true);
    }

    case DISABLE_MAINTENANCE_MODE: {
      return state.set('maintenanceMode', false);
    }

    case LANGUAGE_SET: {
      return state.set('language', action.language);
    }

    case REFERENCE_LOADING_ENABLE_PAGE_LOADER: {
      return state.setIn(['referenceLoading', 'showPageLoader'], true);
    }

    case REFERENCE_LOADING_START: {
      const current = state.getIn(['referenceLoading', 'itemsStarted']);
      return state.setIn(['referenceLoading', 'itemsStarted'], current + 1);
    }

    case REFERENCE_LOADING_RESET: {
      return state.set('referenceLoading', initialState.get('referenceLoading'));
    }

    case SET_CHECKBOX: {
      return state.setIn(['webformState', action.id], action.checked);
    }

    case REFERENCE_LOADING_DONE: {
      const current = state.getIn(['referenceLoading', 'itemsFinished']);
      return state.setIn(['referenceLoading', 'itemsFinished'], current + 1);
    }

    case SHOW_MOBILE_MENU: {
      return state.set('showMobileMenu', true);
    }

    case HIDE_MOBILE_MENU: {
      return state.set('showMobileMenu', false);
    }

    case ROOT_CLASSES_ADD: {
      const classes = List(
        typeof action.classes === 'string'
          ? action.classes.split(' ')
          : action.classes,
      );

      // Filter on those that we have to add.
      const newClasses = classes.filter((item) => {
        const index = state.get('rootClasses').findIndex((c) => c === item);
        return index === -1;
      });

      return state
        .update('rootClasses', (list) => list.merge(newClasses));
    }

    case ROOT_CLASSES_REMOVE: {
      const classes = List(
        typeof action.classes === 'string'
          ? action.classes.split(' ')
          : action.classes,
      );

      return state
        .update('rootClasses', (list) => list.filter((item) => {
          const index = classes.findIndex((c) => c === item);
          return index === -1;
        }));
    }

    default:
      return state;
  }
}
