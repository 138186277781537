import './helpers/publicPath';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import dotenv from 'dotenv';

import { createBrowserHistory } from 'history';

/* eslint-disable */
// We need to load the config before anything else.

import {
  __DEBUG__,
  USE_SENTRY,
  APP_ENV,
  APP_ROOT_ELEMENT_ID,
  SENTRY_DSN,
} from './config/constants'
/* eslint-enable */

import configureStore from './store';
import * as serviceWorker from './serviceWorker';
import App from './containers/App';

import './styles/main.scss';

dotenv.config();

// Don't log env for production
if (__DEBUG__) {
  console.log(`Running environment ${APP_ENV}`);
  console.log(process.env);
}

if (!__DEBUG__ && USE_SENTRY) {
  // TODO: replace DSN, go to https://sentry.uncinc.nl/organizations/sentry/projects
  // create a new project and copy the DSN
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    attachStacktrace: true,
    tracesSampleRate: 1.0,
  });
}

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const store = configureStore(history);

const render = () => {
  ReactDOM.hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById(APP_ROOT_ELEMENT_ID),
  );
};

render();

// Enable Webpack hot module replacement for components.
if (module.hot) {
  module.hot.accept('src/containers/App', () => {
    render();
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
