import React from 'react';
import config from 'react-global-configuration';

import { ImmutableEntity } from '@uncinc/react-drupal-core/types';
import { Table } from '../../../components';
import {
  ParagraphSlideBasic as ParagraphType,
} from '../../../containers/custom-types';

import './style.scss';

interface Props {
  entity: ImmutableEntity<ParagraphType>,
}

const ParagraphSlideBasic: React.FC<Props> = ({ entity }) => {
  const content = entity.toJS();
  const image = `${String(config.get('API_URL'))}${String(content.field_image[0]?.styles?.original_image_crop_teaser)}`;
  const tables = content.field_table;

  return (
    <div className="Paragraph ParagraphSlideBasic">
      <div className="ParagraphSlideBasic__left">
        <img className="ParagraphSlideBasic__image" src={image} alt="" />
      </div>
      <div className="ParagraphSlideBasic__right">
        {tables.map((table, idx) => {
          const { caption, ...items } = table.value;

          const isTableEmpty = Object.values(items).every((item) => {
            const keys = Object.keys(item).filter((key) => key !== 'weight');

            return keys.every((key) => !item[key]);
          });

          if (isTableEmpty) {
            return null;
          }

          return (
            <Table
              // eslint-disable-next-line react/no-array-index-key
              key={`table-${caption}-${idx}`}
              table={table.value}
              className="ParagraphSlideBasic__table"
              subcaption={idx === 0 && (
                <div>
                  <span>Pin kassa</span> <span>/ Cards only</span>
                </div>
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ParagraphSlideBasic;
