import React from 'react';
import config from 'react-global-configuration';
import { ImmutableEntity } from '@uncinc/react-drupal-core/types';
import MetaData from '../MetaData';
import Tag from '../Tag';
import { EventNode } from '../../containers/custom-types';

import './style.scss';

interface Props {
  event: ImmutableEntity<EventNode>,
}

const Event: React.FC<Props> = ({ event: entity }) => {
  const event = entity.toJS();
  const title = event.title[0].value;
  const duration = event.field_duration_text[0]?.value;
  const eventType = event.field_event_type[0]?.entity;
  const heroImageUrl = `${String(config.get('API_URL'))}${String(event.field_hero[0].styles.original_image_crop_teaser)}`;

  return (
    <div className="Event">
      <img className="Event__image" src={heroImageUrl} alt="" />
      <h2 className="asH3">{title}</h2>
      <MetaData
        duration={duration}
      />
      {eventType && <Tag name={eventType.name[0].value} />}
    </div>
  );
};

export default Event;
