import React, { useState, useEffect } from 'react';
import { Paragraph } from '@uncinc/react-drupal-core';
import Immutable from 'immutable';
import config from 'react-global-configuration';
import * as Sentry from '@sentry/react';
import { SliderRelated, WysiwygField } from '@uncinc/uncinc-react-kitchen-sink';
import { ScreenNode } from '../custom-types';
import { API_CALL_INTERVAL, FADE_SPEED, TIME_PER_SLIDE } from '../../config/constants';
import { Footer, ProgressBar, TimeIndicator } from '../../components';
import Error from '../../components/Error';

const Home = () => {
  const API_URL = config.get('API_URL') as string;
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [error, setError] = useState<boolean>(true);
  const [response, setResponse] = useState<ScreenNode>();

  const fetchData = () => {
    fetch(`${API_URL}/api/visitors-screen`)
      .then((res) => res.json())
      .then((res: ScreenNode) => setResponse(res))
      .then(() => setError(false))
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
      });
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, Number(API_CALL_INTERVAL));

    return () => clearInterval(interval);
  }, []);

  if (error && !response) {
    return (
      <Error />
    );
  }

  if (!response) return null;
  const slides = response.field_slides;
  const footerText = response.field_footer_text[0]?.processed ?? '';


  return (
    <>
      <SliderRelated
        slidesToShow={1}
        centerMode={false}
        autoplaySpeed={Number(TIME_PER_SLIDE)}
        speed={Number(FADE_SPEED)}
        touchMove={false}
        pauseOnFocus={false}
        pauseOnHover={false}
        afterChange={(index: number) => {
          setCurrentSlideIndex(index);
        }}
        fade
      >
        {slides.map((slide, index) => (
          <Paragraph
            key={`paragraph:${slide.target_id}`}
            id={slide.target_id}
            entity={Immutable.fromJS(slide.entity)}
            active={index === currentSlideIndex}
          />
        ))}
      </SliderRelated>
      {/* <TimeIndicator
        duration={Number(TIME_PER_SLIDE)}
        interval={currentSlide}
      /> */}
      <Footer
        progressBar={(
          <ProgressBar
            slideCount={slides.length}
            currentSlideIndex={currentSlideIndex}
          />
        )}
      >
        <WysiwygField
          html={footerText}
        />
      </Footer>
    </>
  );
};

export default Home;
