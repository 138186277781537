import React from 'react';
import pictogram from '../../assets/images/logos/pictogram.svg';
import './style.scss';

const Error = () => {
  return (
    <div className="Error">
      <div className="Error__modal">
        <div className="Error__modal__logo">
          <img src={pictogram} alt="Artis groote museum" />
        </div>
        <h2 className="Error_text">Opnieuw verbinden...</h2>
      </div>
    </div>
  );
};

export default Error;
