import React, { useEffect, useState } from 'react';
import { View } from '@uncinc/react-drupal-core';
import { ImmutableEntity } from '@uncinc/react-drupal-core/types';
import {
  ParagraphBlockReference as ParagraphType,
} from '../../../containers/custom-types';

interface Props {
  entity: ImmutableEntity<ParagraphType>,
  active: boolean,
}

const ParagraphBlockReference: React.FC<Props> = ({ entity, active }) => {
  const blockReference = entity.getIn(['field_block_reference', 0, 'value']);

  const [blockType, blockId] = blockReference.split('__') as [string, string];
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (!active) {
      setCurrentPage(currentPage + 1);
    }
  }, [active]);

  // We only support view blocks for now.
  if (blockType === 'views_block') {
    // eslint-disable-next-line prefer-const
    const [viewId] = blockId.split('_', 1);
    const viewDisplay = blockId.replace(`${viewId}_`, '');

    return (
      <div className="Paragraph ParagraphBlockReference">
        <div className="ParagraphBlockReference__view">
          <View
            id={viewId}
            display={viewDisplay}
            page={currentPage}
            setCurrentPage={setCurrentPage}
            active={active}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="Paragraph ParagraphBlockReference">
      <p>Block id: {blockReference}</p>
    </div>
  );
};

export default ParagraphBlockReference;
