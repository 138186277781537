import * as React from 'react';
import config from 'react-global-configuration';

import { ImmutableEntity } from '@uncinc/react-drupal-core/types';
import { ParagraphHighlightedEvent as ParagraphType } from '../../../containers/custom-types';
import { Tag } from '../../../components';
import MetaData from '../../../components/MetaData';

import './style.scss';

interface Props {
  entity: ImmutableEntity<ParagraphType>,
}

const ParagraphHighlightedEvent: React.FC<Props> = ({ entity }) => {
  const event = entity.toJS().field_event_reference[0]?.entity;
  const title = event.title[0].value;
  const eventType = event.field_event_type[0]?.entity;
  const duration = event.field_duration_text[0]?.value;
  const targetAudience = event.field_event_target_audience[0]?.entity?.name[0]?.value;
  const date = event.field_date_text[0]?.value;
  const summary = event.body[0]?.summary;
  const hero = event.field_hero[0];

  return (
    <div className="Paragraph ParagraphHighlightedEvent">
      <img
        className="ParagraphHighlightedEvent__image"
        src={`${String(config.get('API_URL'))}${String(hero.styles.original_image_crop_teaser)}`}
        alt=""
      />
      <header className="ParagraphHighlightedEvent__header">
        {eventType && (
          <div>
            <Tag name={eventType.name[0].value} />
          </div>
        )}
        <h1 className="asH2 ParagraphHighlightedEvent__heading">
          {title}
        </h1>
        <MetaData
          direction="row"
          duration={duration}
          targetAudience={targetAudience}
          time={date}
        />
      </header>
      <div className="ParagraphHighlightedEvent__summary">
        <p className="ParagraphHighlightedEvent__summary-text">
          {summary}
        </p>
      </div>
    </div>
  );
};

export default ParagraphHighlightedEvent;
