import i18next from 'i18next';
import { __DEBUG__, DEFAULT_LANGUAGE } from '../constants';

import nl from './locales/nl.json';

i18next.init({
  fallbackLng: DEFAULT_LANGUAGE,
  fallbackNS: ['translation'],
  resources: {
    nl,
  },
  parseMissingKeyHandler: (missing) => {
    if (__DEBUG__) {
      console.warn('MISSING TRANSLATION:', missing);
    }
    return missing;
  },
});

i18next.languages = ['nl'];

export default i18next;
