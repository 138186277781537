import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import {
  entityReducer,
  menuReducer,
  viewReducer,
  viewInfoReducer,
  nodeReducer,
  webformReducer,
  blockReducer,
  paragraphReducer,
  taxonomyReducer,
  uiReducer,
  loginReducer,
} from '@uncinc/react-drupal-core';

import uiInternal from './UI';

import debug from './Debug';

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  debug,
  entity: entityReducer,
  menu: menuReducer,
  node: nodeReducer,
  webform: webformReducer,
  block: blockReducer,
  paragraph: paragraphReducer,
  view: viewReducer,
  viewInfo: viewInfoReducer,
  taxonomyTerm: taxonomyReducer,
  ui: uiReducer,
  uiInternal: uiInternal,
  login: loginReducer,
});

export default rootReducer;
