import React from 'react';

import { TableField } from '@uncinc/react-drupal-core/types';

import './style.scss';

interface Props {
  table: TableField,
  subcaption?: React.ReactNode,
  className?: string,
}

const Table: React.FC<Props> = ({ table, subcaption, className }) => {
  const { caption } = table;
  const tableItems = table;
  // @ts-ignore
  delete tableItems.caption;
  const items = Object.values(table);

  return (
    <div key={`table-${caption}`} className={`Table ${className}`}>
      <div className="Table__caption-container">
        <h3>{caption}</h3>
        {subcaption && (
          <div className="Table__subcaption">
            {subcaption}
          </div>
        )}
      </div>
      <table>
        <tbody>
          {items.map((item, idx) => {
            const isEmpty = item[0] === '' && item[1] === '';

            if (isEmpty) {
              return null;
            }

            // the last item is the "weight" key, so remove it from counting
            const amountOfItems = Object.keys(item).length - 1;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={`table-${caption}-${item[0]}-${idx}`} className={`Table__row Table__row--amount-of-items-${amountOfItems}`}>
                <td>
                  {item[0]}
                </td>
                <td>
                  {item[1]}
                </td>
                {item[2] && (
                  <td>
                    {item[2]}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
