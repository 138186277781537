import * as React from 'react';

import LogoInverse from '../../assets/images/logos/logo-inverse.svg';
import './style.scss';

interface Props {
  children?: React.ReactNode;
  progressBar: React.ReactNode;
}

const Footer: React.FC<Props> = (props) => {
  const { children, progressBar } = props;

  return (
    <div className="Footer">
      <div className="Footer__container">
        <div className="Footer__progress-bar">
          {progressBar}
        </div>
        <img src={LogoInverse} alt="" className="Footer__logo" />
        {children}
      </div>
    </div>
  );
};

export default Footer;
