import * as React from 'react';

import './style.scss';
import c from 'classnames';
import { FADE_SPEED, TIME_PER_SLIDE } from '../../../config/constants';

interface Props {
  isActive: boolean;
  hasPassed?: boolean;
}

const ProgressBarItem: React.FC<Props> = (props) => {
  const { isActive, hasPassed } = props;

  const classes = c('ProgressBar__item', {
    'ProgressBar__item--active': isActive,
    'ProgressBar__item--passed': hasPassed,
    'ProgressBar__item--waiting': !isActive && !hasPassed,
  });

  return (
    <div className={classes}>
      <div
        className="ProgressBar__item__filler"
        style={{
          animationDuration: `${TIME_PER_SLIDE - FADE_SPEED}ms`,
        }}
      />
    </div>
  );
};

export default ProgressBarItem;
