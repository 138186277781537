import {
  ParagraphBlockReference,
  ParagraphHighlightedEvent,
  ParagraphSlideBasic,
} from '../implementations';

const mappers = {
  block_reference: ParagraphBlockReference,
  screen_slide_basic: ParagraphSlideBasic,
  event_reference: ParagraphHighlightedEvent,
};

export default mappers;
