import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import animationData from './lottie.json';

import './style.scss';

interface Props {
  interval: number,
  duration?: number,
}

const TimeIndicator: React.FC<Props> = ({ duration = 10000, interval }) => {
  useEffect(() => {
    lottie.destroy();

    lottie.loadAnimation({
      // @ts-ignore
      container: document.getElementById('time-indicator'),
      renderer: 'svg',
      autoplay: true,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      animationData,
    });

    lottie.setSpeed(1 / (duration / 1000));
  }, [interval]);

  return (
    <div id="time-indicator" />
  );
};

export default TimeIndicator;
