import React from 'react';

import './style.scss';

interface Props {
  time?: string,
  duration?: string,
  price?: string,
  location?: string,
  targetAudience?: string,
  direction?: 'column' | 'row',
}

const MetaData: React.FC<Props> = (props) => {
  const {
    time,
    duration,
    price,
    location,
    targetAudience,
    direction = 'column',
  } = props;

  return (
    <ul className={`MetaData text-small MetaData--${direction}`}>
      {targetAudience && <li className="icon-before icon-family">{targetAudience}</li>}
      {time && <li className="icon-before icon-clock">{time}</li>}
      {duration && <li className="icon-before icon-hourglass">{duration}</li>}
      {price && <li className="icon-before icon-euro">{price}</li>}
      {location && <li className="icon-before icon-pin">{location}</li>}
    </ul>
  );
};

export default MetaData;
