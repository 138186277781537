export const REFERENCE_LOADING_START = 'REFERENCE_LOADING_START';
export function referenceLoadingStart() {
  return {
    type: REFERENCE_LOADING_START,
  };
}

export const REFERENCE_LOADING_DONE = 'REFERENCE_LOADING_DONE';
export function referenceLoadingDone() {
  return {
    type: REFERENCE_LOADING_DONE,
  };
}

export const REFERENCE_LOADING_RESET = 'REFERENCE_LOADING_RESET';
export function referenceLoadingReset() {
  return {
    type: REFERENCE_LOADING_RESET,
  };
}

export const REFERENCE_LOADING_ENABLE_PAGE_LOADER = 'REFERENCE_LOADING_ENABLE_PAGE_LOADER';
export function referenceLoadingEnablePageLoader() {
  return {
    type: REFERENCE_LOADING_ENABLE_PAGE_LOADER,
  };
}
