import React from 'react';

import {
  I18nProvider,
  EntityMapperContext,
} from '@uncinc/react-drupal-core';

import paragraphMapper from '../../mappers/paragraphs';
import viewsMapper from '../../mappers/views';

import i18next from '../../config/i18next';
import Home from '../Home';

import { __DEBUG__ } from '../../config/constants';

import './style.scss';
import '../../styles/main.scss';

const App = () => {
  const language = 'NL';

  const entityTypeMapper = {
    paragraph: paragraphMapper,
    view: viewsMapper,
  };

  return (
    <EntityMapperContext.Provider value={entityTypeMapper}>
      <I18nProvider locale={language} i18next={i18next}>
        <main className="main">
          <Home />
        </main>
      </I18nProvider>
    </EntityMapperContext.Provider>
  );
};

export default App;
