import * as React from 'react';
import ProgressBarItem from './Item';

import './style.scss';

interface Props {
  slideCount: number,
  currentSlideIndex: number,
}

const ProgressBar: React.FC<Props> = (props) => {
  const {
    slideCount,
    currentSlideIndex,
  } = props;

  return (
    <div className="ProgressBar">
      {Array.from(Array(slideCount).keys()).map((slideIndex) => {
        return (
          <ProgressBarItem
            hasPassed={slideIndex < currentSlideIndex}
            isActive={slideIndex === currentSlideIndex}
            key={`slideIndex-${slideIndex}`}
          />
        );
      })}
    </div>
  );
};

export default ProgressBar;
