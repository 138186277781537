import config from 'react-global-configuration';

export const APP_ENV = process.env.REACT_APP_ENV;
export const __DEBUG__ = APP_ENV !== 'production';
export const USE_SENTRY = process.env.REACT_APP_SENTRY_ENABLED;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const DEFAULT_LANGUAGE = 'nl';
export const SITE_NAME = 'Grootemuseum bezoekers schermen';
export const APP_ROOT_ELEMENT_ID = 'root';

export const IS_SERVER = !(
  typeof window !== 'undefined'
  && window.document
  && window.document.createElement
);
export const IS_CLIENT = !IS_SERVER;

let API_URL = "//groote-museum.devinc.eu";

switch (window.document.location.host) {
  case 'localhost:3000':
    API_URL = "//groote-museum.devinc.eu";
    break;
  case 'accept.tv.grootemuseum.nl':
    API_URL = "//accept.tv.grootemuseum.nl/proxy/tv";
    break;
  case 'staging.tv.grootemuseum.nl':
    API_URL = "//staging.tv.grootemuseum.nl/proxy/tv";
    break;
  case 'tv.grootemuseum.nl':
    API_URL = "//tv.grootemuseum.nl/proxy/tv";
    break;
  default:
    API_URL = "//tv.grootemuseum.nl/proxy/tv";
    break;
}

// let API_URL = 'http://localhost:3000/api'; // <- For local proxy.

// If we provide the API URL in the DOM, use that instead.
if (IS_CLIENT && document.getElementById(APP_ROOT_ELEMENT_ID).dataset.apiUrl) {
  API_URL = document.getElementById(APP_ROOT_ELEMENT_ID).dataset.apiUrl;
}

// The value is where the breakpoint starts (mobile-first).
// These values are taken from '/src/src/styles/assets/_variables.scss'.
export const SCREEN_MOBILE = 0;
export const SCREEN_TABLET = 768;
export const SCREEN_NORMAL = 992;
export const SCREEN_WIDE = 1240;
export const SCREEN_HUGE = 2000;

export const API_CALL_INTERVAL = 30000;
export const TIME_PER_SLIDE = 8000;
export const FADE_SPEED = 1000;

// Use config to share static configuration between components and packages.
config.set({
  'SITE_NAME': SITE_NAME,
  'MULTILINGUAL': false,
  'DEFAULT_LANGUAGE': DEFAULT_LANGUAGE,
  'API_URL': API_URL,
});

