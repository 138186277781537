import React, { useEffect } from 'react';
import { animateScroll } from 'react-scroll';
import { ImmutableEntity, ViewProps } from '@uncinc/react-drupal-core/types';
import { Event } from '../../../components';
import { TIME_PER_SLIDE } from '../../../config/constants';

import { EventNode } from '../../../containers/custom-types';

import './style.scss';

interface Props extends ViewProps {
  entities: ImmutableEntity<EventNode>[],
  active: boolean
}

const ViewEventsList: React.FC<Props> = ({ entities, active }) => {
  const [offsetY, setOffsetY] = React.useState(0);
  const animationFrameRef = React.useRef(0);

  const updateOffsetY = React.useCallback(() => {
    const currentOffsetY = window.scrollY;
    setOffsetY(currentOffsetY);
    animationFrameRef.current = requestAnimationFrame(updateOffsetY);
  }, [animationFrameRef, setOffsetY]);

  React.useEffect(() => {
    if (active) {
      animationFrameRef.current = requestAnimationFrame(updateOffsetY);
    } else {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = 0;
    }
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [active,
    animationFrameRef,
    updateOffsetY,
  ]);

  useEffect(() => {
    if (active) {
      animateScroll.scrollToBottom({
        duration: TIME_PER_SLIDE - 4000,
        delay: 2000,
        smooth: 'linear',
        offset: 0,
        containerId: 'ViewEventsList',
      });
    } else {
      animateScroll.scrollToTop({
        duration: 1000,
        delay: 0,
        smooth: 'linear',
        offset: 0,
        containerId: 'ViewEventsList',
      });
    }
  }, [active]);

  if (!entities) return null;

  return (
    <div className="ViewEventsList" id="ViewEventsList">
      <header
        className="ViewEventsList__header"
        style={{
          transform: `translate3d(0, ${offsetY}px, 0)`,
        }}
      >
        <h1 className="asH2">
          Elke dag te zien
        </h1>
      </header>
      <div className="ViewEventsList__items">
        {entities.map((entity) => {
          const uuid = entity.getIn(['uuid', 0, 'value']);
          const langcode = entity.getIn(['langcode', 0, 'value']);

          return (
            <div key={`${uuid}:${langcode}`} className="ViewEventsList__row">
              <Event event={entity} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ViewEventsList;
