import React from 'react';

import './style.scss';

interface Props {
  name: string,
}

const Tag: React.FC<Props> = ({ name }) => (
  <div className="Tag text-small">
    {name}
  </div>
);

export default Tag;
